import React, { useCallback } from "react";

import Popup from "../Popup/Popup";
import Button from "../Forms/Button/Button";

type Props = {
  id: string;
  isVisible: boolean;
  setIsVisible: any;
  bodyText: string;
  onConfirmation: any;
  confirmButtonText: string;
  cancelButtonText: string;
};
const ConfirmationAlert = ({
  id,
  isVisible,
  setIsVisible,
  bodyText,
  onConfirmation,
  confirmButtonText,
  cancelButtonText,
}: Props) => {
  const confirmationCallbackHandler = useCallback(() => {
    onConfirmation();
    setIsVisible(false);
  }, [isVisible]);

  return (
    <Popup
      isOpen={isVisible}
      setIsOpen={setIsVisible}
      innerStyle={{ overflow: "visible" }}
    >
      <div className="mt-4 px-3" id={id}>
        <div className="text-center">
          <p
            className="font-normal text-lg text-gray-800 font-sans leading-6"
          >{bodyText}</p>
        </div>

        <div className="d-flex justify-content-center pt-2 gap-3">
          <Button
            id={`${id}${cancelButtonText}Button`}
            customClass="rounded-full py-[12px] w-48 flex items-center justify-center text-sm font-semibold text-white border border-gray-300 bg-blue-lighter"
            type={"button"}
            onClick={() => setIsVisible(false)}
          >
            {cancelButtonText}
          </Button>
          <Button
            id={`${id}${confirmButtonText}Button`}
            customClass="rounded-full py-[12px] w-48 flex items-center justify-center text-sm font-semibold text-blue-lighter border border-gray-300 "
            onClick={() => confirmationCallbackHandler()}
            type={"submit"}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmationAlert;
