import FactoryService from "../FactoryService";

import {ResultCountriesInterface} from "../../Models/Country";
import AccountingBaseService from "../AccountingBaseService";

const factoryService = new FactoryService();

class SubscriptionService {
    private SUBSCRIPTION_ENDPOINT: string = "api/Subscriptions";

    async getCurrentSubscription(): Promise<ResultCountriesInterface> {
        return await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.SUBSCRIPTION_ENDPOINT}/current`, true);
    }

    async cancelSubscription(): Promise<ResultCountriesInterface> {
        return await (
            await factoryService.create(AccountingBaseService)
        ).delete(this.SUBSCRIPTION_ENDPOINT, true);
    }


}

export default new SubscriptionService();
