import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Button, Divider, Spin} from "antd";
import {SubmitHandler, useForm} from "react-hook-form";

import Input from "../../Components/Forms/Input/Input";
import {ReactComponent as TrophyIcon} from "../../Assests/Icons/trophy-outlined.svg";
import {ReactComponent as CheckIcon} from "../../Assests/Icons/check-blue.svg";

import AccountingService from "../../Services/Accounting/AccountingService";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Toast from "../../Utils/ToastHandler";
import {
    TABLET_MAX_WIDTH_QUERY,
    TOAST_BOTTOM_CENTER,
    TOAST_DURATION
} from "../../Constants/Values";
import AssetsService from "../../Services/Assets/AssetsService";

import "./Styles.scss";
import {gtagEventHandler} from "../../Utils/gtagEventHandler";
import {CloseOutlined, WarningOutlined} from "@ant-design/icons";
import {ROUTE_CONSTANTS} from "../../Routes/RouteConstants";

import {ReactComponent as LockIcon} from "../../Assests/Icons/lock.svg";
import {currencyFormatter} from "../../Utils/currencyFormatter";
import accountingService from "../../Services/Accounting/AccountingService";
import {SingleSubscriptionProps, SubscriptionPlanType} from "../../Models/Subscription";
import useMatchMedia from "../../Hooks/useMatchMedia";
import {useAppData} from "../../Context/AppContext";
import SubscriptionService from "../../Services/Subscription/SubscriptionService";
import {useNavigate} from "react-router";
import {PRICING_PLANS} from "../../Utils/constants";

const {
    REACT_APP_PAYMENT_MODEL,
    REACT_APP_INFO_URL
} = (window as any).__env__;

type topUpPayloadType = {
    totalAmount: number;
    countryIso2?: string;
    paymentMethod: string;
    credit: number;
}

type TopUpType = {
    amount: number;
    paymentMethods: any;
    creditRate: number;
    taxRate: string;
    currency: string;
    credit: number;
}

const TopUp = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: {errors},
    } = useForm<TopUpType>({
        mode: 'onBlur',
    });
    const {match: isMobileDevice} = useMatchMedia(TABLET_MAX_WIDTH_QUERY);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [topUp, setTopUp] = useState<TopUpType>({
        amount: 0.00,
        paymentMethods: {},
        creditRate: 0,
        taxRate: "",
        currency: "EUR",
        credit: 0,
    });
    const [step, setStep] = useState<number>(1);
    const [externalUrl, setExternalUrl] = useState<string>("");
    const [countryIso2, setCountryIso2] = useState<string>("");
    const [subscriptionPlans, setSubscriptionPlans] = useState<Array<SubscriptionPlanType>>();
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<number | null>(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>("1");
    const [hasMissingBillingInfo, setHasMissingBillingInfo] = useState<boolean>(false);

    const {setGoToPricing, showPricingPage} = useAppData();

    const getRegion = () => {
        setIsLoading(true);
        AssetsService.getRegion().then((res: any) => {
            const countryRegion = res.region;

            setCountryIso2(countryRegion);

            AccountingService.getTopUpPreview(countryRegion).then((response) => {
                setTopUp({
                    ...topUp,
                    paymentMethods: response.paymentMethods,
                    creditRate: response.creditRate,
                    taxRate: response.vat,
                    currency: response.currencyIso3,
                });

                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
                setStep(3);
            });
        }).catch(e => {
            setIsLoading(false);
            setStep(3);
        })
    }

    const submitForm: SubmitHandler<TopUpType> = () => {

        setIsLoading(true);
        const payload: topUpPayloadType = {
            totalAmount: topUp.amount,
            countryIso2: countryIso2,
            credit: topUp.credit,
            paymentMethod: selectedPaymentMethod,
        };

        AccountingService.topUp(payload).then((response) => {
            const redirectUrl = response.url;
            setExternalUrl(redirectUrl);
            setStep(2);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);

            const error = err.response.data;

            Toast.error(
                error ? t(error) : t("generic-error"),
                TOAST_DURATION,
                TOAST_BOTTOM_CENTER
            );
        });
    }

    const totalCostHandler = (type: string) => {
        const tax = topUp.amount * parseInt(topUp.taxRate) / 100;
        const creditCost = topUp.credit * topUp.creditRate;

        switch (type) {
            case "total":
                return (creditCost.toFixed(2) || 0);
            case "tax":
                return tax.toFixed(2);
            case "cost":
                return (creditCost - tax).toFixed(2);
            default:
                return 0.0;
        }
    }

    const topupFormHandler = () => {
        return <form onSubmit={handleSubmit(submitForm)} className={` ${!isMobileDevice ? "col-md-8" : ""}`}>
            <div className="row">
                <label htmlFor="credit"
                       className="font-sans text-sm font-normal text-light-black tracking-wider mb-2.5">
                    {t("Topup-Credit-Rate")}
                </label>
                <Input
                    id="credit"
                    name="credit"
                    type="number"
                    min="0"
                    value={String(topUp.credit)}
                    withMargin={false}
                    required={true}
                    customRef={register("credit", {
                        onChange: (e) => {
                            const totalAmount = parseInt(e.target.value) * topUp.creditRate;

                            setTopUp({
                                ...topUp,
                                credit: parseInt(e.target.value) || 0,
                                amount: totalAmount || 0.00,
                            })

                            gtagEventHandler({
                                event_category: "Topup",
                                event_label: "Credit",
                                value: parseInt(e.target.value),
                            }, "topup_credit");

                        },
                        required: {
                            value: true,
                            message: t("Topup-Credit-Required"),
                        },
                        min: {
                            value: "1",
                            message: t("Topup-Incorrect-Credit"),
                        },
                        pattern: {
                            value: /^[0-9]+$/,
                            message: t("Topup-Incorrect-Credit"),
                        }
                    })}
                    error={errors.credit?.message}
                    labelType={"none"}
                    divClassName="col-md-12"
                    inputClassName="form-control font-sans text-sm font-normal text-light-black tracking-wider px-4 py-3"
                />
            </div>

            <div className="mt-2">
                <span
                    className="font-sans text-sm tracking-wider font-normal text-gray-darker">{t("topup-price-breakdown")}</span>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                <span
                    className="font-sans text-sm tracking-wider font-normal text-gray-darker">{topUp.credit + " " + t("Topup-Credit-Rate")}{(topUp.credit > 1) ? "s" : ""}</span>
                <strong
                    className="font-sans text-sm tracking-wider font-bold text-light-black">{topUp.currency + " " + totalCostHandler("cost")}</strong>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center">
                <span
                    className="font-sans text-sm tracking-wider font-normal text-gray-darker">{t("Topup-Tax-Rate") + " " + topUp.taxRate + "%"}</span>
                <strong className="font-sans text-sm tracking-wider font-bold text-light-black">{
                    topUp.currency + " " + totalCostHandler("tax")
                }</strong>
            </div>

            <Divider dashed={true}/>

            <div className="d-flex flex-row justify-content-between align-items-center">
                <span
                    className="font-sans text-sm tracking-wider font-normal text-gray-darker">{t("Topup-Total-Cost")}</span>
                <strong
                    className="font-sans text-sm tracking-wider font-bold text-light-black">{topUp.currency} {totalCostHandler("total")}</strong>
            </div>

            <Divider dashed={true}/>
            <div className="col-md-12">
                <label htmlFor="paymentMethod"
                       className="ffont-sans text-sm font-normal text-light-black tracking-wider mb-2.5">
                    {t("Topup-Payment-Method")}
                </label>
                <Dropdown
                    selectclass="form-select font-sans text-xs font-normal text-light-black tracking-wider"
                    style={{
                        fontSize: "14px",
                        padding: "18px 16px",
                    }}
                    id="paymentMethod"
                    name="paymentMethod"
                    onChange={(e) => {
                        setSelectedPaymentMethod(e.target.value);

                        gtagEventHandler({
                            event_category: "Topup",
                            event_label: "Payment Method",
                            value: e.target.value,
                        }, "topup_payment_method");
                    }}
                    value={selectedPaymentMethod}
                    options={
                        topUp.paymentMethods && Object.keys(topUp.paymentMethods).map((key: any) => {
                                return {
                                    name: topUp.paymentMethods[key],
                                    value: key,
                                };
                            }
                        )
                    }
                />
            </div>

            <div className="flex items-center flex-col justify-center pt-14">
                <button
                    id="saveButton"
                    type="submit"
                    className="flex bg-lemon-green items-center justify-center text-white rounded-full px-14 py-2.5"
                    disabled={isLoading}
                    onClick={() => {
                        gtagEventHandler({
                            event_category: "Topup",
                            event_label: "Proceed Payment",
                        }, "topup_proceed_payment");
                    }}
                >
                    {t("Topup-Proceed-Payment")}
                </button>

                <div className="mt-6 flex items-center">
                    <LockIcon/>
                    <div className="flex flex-col ms-2">
                        <span
                            className="font-sans text-xs font-light text-gray-darker tracking-wider">{t("topup-your-payment-is-protected")}</span>
                        <span
                            className="font-sans text-xs font-light text-gray-darker tracking-wider">{t("topup-advanced-protection")}</span>
                    </div>
                </div>
            </div>

        </form>
    }

    const paymentPageHandler = () => {
        if (!externalUrl) {
            return <div className="col-md-12">
                <div className="alert alert-danger d-flex justify-content-center flex-column align-items-center"
                     role="alert">
                    {t("Topup-Redirect-Payment-Error")}
                </div>
            </div>
        } else {
            window.open(externalUrl, "_self");
        }

    }

    const IncompleteProfileHandler = () => {
        return <div className="col-md-8">
            <div className="alert alert-warning d-flex justify-content-center flex-column align-items-center"
                 role="alert">
                {t("Topup-Incomplete-Profile")}

                <Button
                    type="primary"
                    className="save-button my-4"
                    onClick={() => {
                        gtagEventHandler({
                            event_category: "Topup",
                            event_label: "Incomplete Profile",
                        }, "topup_incomplete_profile");

                        window.location.href = `${ROUTE_CONSTANTS.DASHBOARD}?tab=profile`
                    }}
                >
                    {t("Topup-Goto-Profile")}
                </Button>
            </div>
        </div>
    }

    const subscriptionHandler = (planId: number) => {
        const payload: SingleSubscriptionProps = {
            useIFrame: true,
            subscriptionId: planId
        }

        setIsLoading(true);
        accountingService.subscribe(payload).then((response) => {
            const redirectUrl = response?.url;
            window.open(redirectUrl, "_self")
            setIsLoading(false);
        }).catch((err) => {
            const error = t(err?.response?.data) || t("generic-error");

            if (err?.response?.data === "MissingBillingDetails") {
                setHasMissingBillingInfo(true);
            } else {
                Toast.error(
                    error,
                    TOAST_DURATION,
                    TOAST_BOTTOM_CENTER
                );
            }

            setIsLoading(false);
        });
    }

    const subscriptionPlanDetailHandler = () => {
        const selectedPlan: SubscriptionPlanType | null = subscriptionPlans?.find((plan) => plan.id === selectedSubscriptionPlan) || null;

        if (!selectedPlan) {
            return null;
        }

        return <div className="w-2/3 lg:w-2/4">
            <TrophyIcon/>

            <h5 className="text-sm font-sans font-light text-gray-darker my-4">
                {t(PRICING_PLANS?.find((plan: any) => plan?.pricing_name?.name === selectedPlan?.name.toLowerCase())?.description || "")}
            </h5>

            <h5 className="text-4xl font-sans text-[#003E4F] mb-4 font-bold">
                {currencyFormatter(selectedPlan.amount, selectedPlan.currencyIso3 || "EUR")}
            </h5>

            {PRICING_PLANS?.find((plan: any) => plan?.pricing_name?.name === selectedPlan.name.toLowerCase())?.features.map((feature: any, index: number) => {
                return <div className="flex items-center mb-2">
                        <span className="text-base font-sans font-medium ms-2 text-gray-darker">
                            <strong
                                className="flex items-center">
                            <CheckIcon/>
                            <span className="ms-2 text-gray-darkest">{t(feature.feature || "")}: </span>
                        </strong>
                            <span className="text-sm font-sans font-light">{t(feature.featuredesc || "")}</span>
                        </span>
                </div>
            })
            }

            <div className="flex justify-content-center mt-4">
                <button
                    className="flex bg-blue items-center justify-center rounded-full w-full py-[10px] text-white uppercase"
                    disabled={!selectedPlan?.id || isLoading}
                    onClick={() => {
                        const planId = selectedPlan?.id || null

                        if (!planId) {
                            return;
                        }

                        subscriptionHandler(planId);
                    }}
                >
                    {t("topup-subscription-buy")}
                </button>
            </div>

        </div>
    }

    const subscriptionViewHandler = () => {
        return <div className={`col-md-10 ${isMobileDevice ? "m-auto" : ""}`}>

            <div className="flex justify-content-center">
                <div className="flex justify-content-center border-2 border-gray-light rounded-full">
                    {subscriptionPlans && subscriptionPlans.map((plan) => {
                        return <button
                            key={plan?.id}
                            className={`flex items-center justify-center rounded-full w-32 py-[10px] ${selectedSubscriptionPlan === plan.id ? "bg-blue text-white" : ""}`}
                            onClick={() => {
                                setSelectedSubscriptionPlan(plan?.id);

                                gtagEventHandler({
                                    event_category: "Topup",
                                    event_label: "Subscription Plan",
                                    value: plan.id || "",
                                }, "topup_subscription_plan");
                            }}
                        >
                            {plan?.name}
                        </button>
                    })}
                </div>
            </div>

            <div
                className="flex justify-content-center mt-10 border-2 border-gray-light rounded-md shadow-md p-4"
            >
                {subscriptionPlanDetailHandler()}
            </div>

        </div>
    }

    const fetchAllPricingPlans = () => {
        setIsLoading(true);
        AccountingService.getPricingPlans().then((response) => {
            setSubscriptionPlans(response?.result);

            if (response?.result) {
                setSelectedSubscriptionPlan(response?.result[0].id);
            }

            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const topUpStepHandler = () => {
        switch (step) {
            case 1:
                return topupFormHandler();
            case 2:
                return paymentPageHandler();
            case 3:
                return IncompleteProfileHandler();
            default:
                return topupFormHandler();
        }
    }

    const checkCurrentSubscription = () => {
        setIsLoading(true);
        SubscriptionService.getCurrentSubscription().then((response: any) => {
            const subscription = {
                name: response?.name,
                expired: response?.expired || false,
                inGracePeriod: response?.inGracePeriod || false,
            };

            if (subscription.name?.toLowerCase() === "trial" || subscription.expired || subscription.inGracePeriod) {
                fetchAllPricingPlans();
            } else {
                navigate(ROUTE_CONSTANTS.DASHBOARD);
            }

            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
            navigate(ROUTE_CONSTANTS.DASHBOARD)
        });
    }

    useEffect(() => {
        getRegion();

        gtagEventHandler({
            event_category: "Topup",
            event_label: "Topup Page",
            page_path: window.location.pathname,
        }, "topup_page");

        if (REACT_APP_PAYMENT_MODEL !== "CREDIT") {
            checkCurrentSubscription();
        }

        if (showPricingPage()) {
            setGoToPricing(false);
        }

    }, []);

    return (
        <div className="xl:row ">

            {
                hasMissingBillingInfo &&
                <div
                    className={`flex justify-between ${!isMobileDevice ? "col-md-10" : ""} border-b-2 border-gray-light py-6 xl:py-3 ${isMobileDevice ? "px-4" : ""}`}>
                    <div
                        className="text-red rounded font-sans text-base font-normal flex items-center"
                        role="alert"
                    >
                        <strong className="font-bold">
                            <WarningOutlined
                                rev={undefined}
                                className="hand-pointer text-red text-base pe-2"
                            />
                        </strong>
                        <span className="block sm:inline">{
                            <Trans i18nKey={t("MissingBillingDetails")} components={{
                                site_anchor: <a
                                    href={REACT_APP_INFO_URL}
                                    target="_blank"
                                    className="text-red underline"
                                />
                            }}/>
                        }</span>
                    </div>

                    <CloseOutlined
                        rev={undefined}
                        size={8}
                        onClick={() => {
                            setHasMissingBillingInfo(false);
                        }}
                        className="hand-pointer text-red text-sm"
                    />
                </div>
            }

            <div className="hidden xl:flex justify-between col-md-10 ">
                <h5
                    className="text-5xl text-gray-dark font-black font-sans my-10"
                >
                    {REACT_APP_PAYMENT_MODEL === "CREDIT" ? t("topup-title") : t("topup-pricing-title")}
                </h5>

                <CloseOutlined
                    rev={undefined}
                    onClick={() => {
                        window.location.href = ROUTE_CONSTANTS.DASHBOARD;
                    }}
                    className="hand-pointer"
                />
            </div>

            {REACT_APP_PAYMENT_MODEL === "CREDIT" ?
                <div
                    className={`xl:m-auto xl:mt-4 xl:py-1 py-20 px-4 mt-[-6rem] ${isMobileDevice ? "bg-white" : "bg-transparent col-md-7"} rounded-t-[20px]`}>
                    {!isLoading && topUpStepHandler()}
                </div> :
                <div
                    className={`xl:m-auto xl:mt-4 xl:py-1 py-20 px-4 mt-[-6rem] ${isMobileDevice ? "bg-white" : "bg-transparent"} rounded-t-[20px]`}>
                    {!isLoading && subscriptionViewHandler()}
                </div>
            }

            {isLoading && <div className="spinner-container xl:col-md-8 backdrop-blur-md ">
                <Spin size="large"/>
            </div>}
        </div>
    );
}

export default TopUp;