import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {Button, Modal, Table} from "antd";
import type {ColumnsType} from 'antd/es/table';
import {useTranslation} from "react-i18next";
import {FileTextOutlined, DownloadOutlined, CloseOutlined} from '@ant-design/icons';
import dayjs from "dayjs";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

import Search from "antd/es/input/Search";
import ReportingService from "../../Services/Reporting/ReportingService";
import {ReactComponent as SuccessIcon} from "../../Assests/Icons/success-circle.svg";
import {ReactComponent as FailIcon} from "../../Assests/Icons/failed-circle.svg";

import Toast from "../../Utils/ToastHandler";

import {
    SMALL_MOBILE_MAX_WIDTH_QUERY,
    TABLET_MAX_WIDTH_QUERY,
    TOAST_BOTTOM_CENTER,
    TOAST_DURATION,
    VAT_REGISTRATION_NUMBER
} from "../../Constants/Values";

import "./Styles.scss";
import AccountingService from "../../Services/Accounting/AccountingService";
import {currencyFormatter} from "../../Utils/currencyFormatter";
import {ROUTE_CONSTANTS} from "../../Routes/RouteConstants";
import useMatchMedia from "../../Hooks/useMatchMedia";
import envConfig from "../../Configs/env.config";
import SubscriptionService from "../../Services/Subscription/SubscriptionService";

const {
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

type Transaction = {
    transactionExternalReference: string;
    currencyIso3: string;
    signedAmount: number;
    createdOn: string;
    transactionTypeName: string;
    transactionStatusName: string;
    signedCredit: number;
    transactionNote: string;
    creditRate: string;
};

type InvoiceProps = {
    credit: number;
    creditRate: number;
    currencyIso3: string;
    email: string;
    firstName: string;
    lastName: string;
    paymentDateTime: string;
    paymentProvider: string;
    paymentProviderMethod: string;
    paymentProviderTransactionId: string;
    signedSubTotal: number;
    signedTotal: number;
    signedVatTotal: number;
    taxCountry: string;
    transactionId: string;
    transactionReference: string;
    subscriptionName?: string;
    vat: number;
}

const Dashboard = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const modalRef = useRef<any>(null);
    const {match: isMobileDevice} = useMatchMedia(TABLET_MAX_WIDTH_QUERY);
    const {match: isSmallMobileDevice} = useMatchMedia(SMALL_MOBILE_MAX_WIDTH_QUERY);

    const queryParams = new URLSearchParams(location.search);
    const searchFilter = queryParams.get('search');
    const invoiceFilter = queryParams.get('reference');
    const [tableData, setTableData] = useState<any[]>([]);
    const [search, setSearch] = useState<string>(searchFilter ? searchFilter : invoiceFilter ? invoiceFilter : "");
    const [loading, setLoading] = useState<boolean>(false);
    const [paginationData, setPaginationData] = useState({
        totalRecords: 0,
        totalPages: 0,
        pageNumber: 2,
    });
    const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<InvoiceProps>({
        credit: 0,
        creditRate: 0,
        currencyIso3: "EUR",
        email: "",
        firstName: "",
        lastName: "",
        paymentDateTime: "",
        paymentProvider: "",
        paymentProviderMethod: "",
        paymentProviderTransactionId: "",
        signedSubTotal: 0,
        signedTotal: 0,
        signedVatTotal: 0,
        taxCountry: "",
        transactionId: "",
        transactionReference: "",
        vat: 0,
    });

    const [inGracePeriod, setInGracePeriod] = useState<boolean>(false);

    const modalStyles = {
        mask: {
            backdropFilter: 'blur(10px)',
        },
    };

    const columns: ColumnsType<Transaction> = [
        {
            title: t("dashboard-date"),
            dataIndex: 'createdOn',
            render: (text) => {
                return <span
                    className="text-light-black font-medium font-sans leading-normal"
                >{dayjs(text).format("DD/MM/YYYY")}</span>
            },
        },
        {
            title: t("dashboard-time"),
            responsive: ['xl'],
            dataIndex: 'createdOn',
            render: (text) => {
                return <span
                    className="text-light-black font-medium font-sans leading-normal"
                >{dayjs(text).format("HH:mm")}</span>
            },
        },
        {
            title: t("dashboard-type"),
            dataIndex: 'transactionTypeName',
            render: (text) => {
                return <span
                    className={`${text === "Win" ? "text-dark-green" : text === "Loss" ? "text-red" : "text-blue-lighter"} font-semibold font-sans leading-normal`}
                >{t("dashboard-type-" + text, {defaultValue: text})}</span>
            },
        },
        {
            title: t("dashboard-credit"),
            width: "auto",
            dataIndex: 'signedCredit',
            render: (text) => {
                if (text < 0) {
                    return <span className="text-danger font-semibold font-sans leading-normal">{text}</span>
                }

                return <span className="text-light-black font-semibold font-sans leading-normal">{text}</span>
            },
            sorter: {
                compare: (a, b) => a.signedCredit - b.signedCredit,
                multiple: 1,
            },
            hidden: REACT_APP_PAYMENT_MODEL !== "CREDIT"
        },
        {
            title: isSmallMobileDevice ? 'Amo' : t("dashboard-amount"),
            dataIndex: 'signedAmount',
            responsive: ['xl'],
            render: (text, record) => {
                const currency = record.currencyIso3;

                const status = record.transactionStatusName;
                const type = record.transactionTypeName;
                const transactionRef = record.transactionExternalReference;


                return isMobileDevice ? <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    {currency ? <span
                            className="text-light-black font-semibold font-sans leading-normal"
                            style={{
                                textDecoration: status === "Done" && type === "Top up" ? "underline" : "none",
                                cursor: status === "Done" && type === "Top up" ? "pointer" : "default",
                                color: status === "Done" && type === "Top up" ? "#1890ff" : "inherit",
                            }}
                            onClick={() => {
                                if (status === "Done" && type === "Top up") {
                                    fetchInvoiceHandler(transactionRef);
                                }
                            }}
                        >{currencyFormatter(text, currency)}</span> :
                        <span className="text-light-black font-semibold font-sans leading-normal">€{text}</span>}
                </div> : <>{currency ? <span
                        className="text-light-black font-semibold font-sans leading-normal">{currencyFormatter(text, currency)}</span> :
                    <span className="light-black font-semibold font-sans leading-normal">€{text}</span>}</>
            },
            sorter: {
                compare: (a, b) => a.signedAmount - b.signedAmount,
                multiple: 2,
            },
        },
        {
            title: t("dashboard-event"),
            width: 200,
            dataIndex: 'transactionNote',
            responsive: ['xl'],
            render: (text) => {
                return text.length > 20 ? <span
                        className="text-light-black font-normal font-sans leading-normal">{text.slice(0, 30)}...</span> :
                    <span className="light-black font-medium font-sans leading-normal">{text}</span>
            },
        },
        {
            title: t("dashboard-reference"),
            render: (text) => {
                return <span className="text-gray-darker font-normal font-sans leading-normal">{text.slice(-12)}</span>
            },
            dataIndex: 'transactionExternalReference',
            responsive: ['xl'],
        }
    ];

    const fetchInvoiceHandler = (transactionId: string) => {
        setLoading(true);
        AccountingService.getInvoice(transactionId).then((res) => {
            setInvoiceData(res);

            setLoading(false);
            setIsInvoiceModalVisible(true);
        }).catch((err) => {
            setLoading(false);
            Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        });
    }

    const fetchTransactionHandler = (pageNumber = 1) => {
        setLoading(true);
        ReportingService.getReporting(search, pageNumber).then((res) => {

            //TODO: Has to be more efficient and the key value should be returned from the server
            setTableData(res?.result.map((item: any, index: number) => {
                return {
                    ...item,
                    key: index,
                }
            }));

            setPaginationData({
                ...paginationData,
                totalPages: res?.totalPages,
                totalRecords: res?.totalItemCount,
            });
            setLoading(false);
        }).catch((err) => {
            Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
            setLoading(false);
        });
    }

    const getEmailFromToken = () => {
        const token = localStorage.getItem("token");

        if (token) {
            const payload = token.split(".")[1];
            const decodedPayload = window.atob(payload);
            const email = JSON.parse(decodedPayload).email;

            if (!email || email === "") {
                return <div
                    className="alert alert-warning mb-3 xl:me-6 d-flex justify-content-between align-items-center">
                <span className="text-orange">
                    {t("dashboard-provide-email-address")}
                </span>
                    <Button
                        href={`${ROUTE_CONSTANTS.DASHBOARD}?tab=profile`}
                        className="text-orange"
                    >
                        {
                            t("dashboard-go-to-profile")
                        }
                    </Button>
                </div>;
            }
        }
        return;
    }

    const handleDownloadPDF = (invoiceId: string) => {
        const input = document.getElementById('invoice');
        if (input) {
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210;
                const pageHeight = 295;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save(`Receipt-${invoiceId}.pdf`);
            });
        }
    };

    const getCurrentSubscription = () => {
        setLoading(true);
        SubscriptionService.getCurrentSubscription().then((response: any) => {
            const inGracePeriod = response?.inGracePeriod || false;

            setInGracePeriod(inGracePeriod);

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchTransactionHandler();

        if (REACT_APP_PAYMENT_MODEL !== "CREDIT") {
            getCurrentSubscription();
        }
    }, []);

    useEffect(() => {
        if (invoiceFilter) {
            fetchInvoiceHandler(invoiceFilter);
        }
    }, [invoiceFilter]);

    return (
        <div
            className="px-3 xl:pe-6 mt-[-20px] xl:mt-0 mb-10"
        >
            <h5
                className="text-5xl text-gray-dark font-black font-sans my-10 hidden xl:block"
            >{t("dashboard-game-records")}</h5>
            {getEmailFromToken()}

            {inGracePeriod && <div
                className="alert alert-warning mb-3 xl:me-6 d-flex justify-content-between align-items-center">
                <span className="text-orange">
                    {t("dashboard-grace-period-message")}
                </span>
                <Button
                    href={ROUTE_CONSTANTS.TOP_UP}
                    className="text-orange"
                >
                    {t("dashboard-grace-period-go-to-plans")}
                </Button>
            </div>}


            {/*TODO: had to be uncommented*/}
            {/*<div className="float-end d-flex justify-content-center">*/}
            {/*    <Search*/}
            {/*        placeholder="Search"*/}
            {/*        defaultValue={search}*/}
            {/*        onChange={(e) => setSearch(e.target.value)}*/}
            {/*        onSearch={() => fetchTransactionHandler()}*/}
            {/*        enterButton*/}
            {/*        className="mb-3 float-end"*/}
            {/*        size={"large"}*/}
            {/*    />*/}
            {/*</div>*/}

            <Table
                columns={columns}
                dataSource={tableData}
                className="shadow-lg bg-white rounded-lg xl:me-6 font-sans"
                loading={loading}
                rowClassName="text-sans text-light-black"
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: false,
                    position: ['bottomRight'],
                    total: paginationData.totalRecords,
                }}

                {...(isMobileDevice ? {
                    expandable: {
                        expandedRowRender: record => <div>
                            <div className="flex flex-col items-start justify-center mb-3">
                                <h5 className="text-xs text-gray-darker font-normal uppercase font-sans">{t("dashboard-reference")}</h5>
                                <span
                                    className="text-xs text-light-black font-normal font-sans">{record.transactionExternalReference}</span>
                            </div>
                            <div className="flex flex-col flex-wrap items-start justify-center mb-3">
                                <h5 className="text-xs text-gray-darker font-normal uppercase font-sans">{t("dashboard-event")}</h5>
                                <span
                                    className="text-xs text-light-black font-normal font-sans">{record.transactionNote}</span>
                            </div>
                            <div className="flex flex-col flex-wrap items-start justify-center mb-3">
                                <h5 className="text-xs text-gray-darker font-normal uppercase font-sans">{t("dashboard-time")}</h5>
                                <span
                                    className="text-xs text-light-black font-normal font-sans">
                                    {dayjs(record.createdOn).format("HH:mm")}
                                </span>
                            </div>

                            <div className="flex flex-col flex-wrap items-start justify-center">
                                <h5 className="text-xs text-gray-darker font-normal uppercase font-sans">{t("dashboard-amount")}</h5>
                                <span
                                    className="text-xs text-light-black font-normal font-sans">
                                    {record?.currencyIso3 ? currencyFormatter(record.signedAmount, record.currencyIso3) : `€${record.signedAmount}`}
                                </span>
                            </div>


                        </div>,
                        rowExpandable: record => record.transactionExternalReference !== '',
                    },
                } : {})}

                onChange={(paginationData) => fetchTransactionHandler(paginationData.current)}
            />

            <Modal

                title="InplayTip Receipt Details"
                centered
                open={isInvoiceModalVisible}
                width={1000}
                onCancel={() => setIsInvoiceModalVisible(false)}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setIsInvoiceModalVisible(false)}
                        danger
                    >
                        <div>
                            <span>{t("Cancel-Button")}</span> <CloseOutlined rev={undefined}/>
                        </div>
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        handleDownloadPDF(invoiceData.transactionId)
                    }}
                    >
                        <div>
                            <span>{t("Download-Button")}</span> <DownloadOutlined rev={undefined}/>
                        </div>
                    </Button>,
                ]}
                styles={modalStyles}
            >
                <div id="invoice" ref={modalRef} className="p-3">
                    <div className="d-flex justify-content-between my-3">
                        <h4 className="text-center receipt-header">InplayTip</h4>
                        <div className="d-flex justify-content-end"><span className="h5">{t("dashboard-receipt")}</span>
                        </div>
                    </div>


                    <div className="d-flex justify-content-end my-4">
                        <div className="text-start">
                            <span>{`${invoiceData.firstName} ${invoiceData.lastName}`}</span><br/>
                            <span>{t("dashboard-date")} {dayjs(invoiceData.paymentDateTime).format("DD/MM/YYYY HH:mm:ss")}</span><br/>
                            <span> {t("dashboard-receipt-no")} {invoiceData.transactionId}</span>
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                        <tr className="table-row-dark">
                            <th>{t("dashboard-description")}</th>
                            <th>{t("dashboard-qty")}</th>
                            <th>{t("dashboard-unit-price")}</th>
                            <th>{t("dashboard-amount")}{` ${invoiceData?.currencyIso3}`}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="table-row-dark">
                            <td>{
                                REACT_APP_PAYMENT_MODEL === "CREDIT" ? t("dashboard-credit-topup") : `${t("dashboard-subscription-topup")} - ${invoiceData?.subscriptionName || ""}`
                            }</td>
                            <td>{invoiceData.credit}</td>
                            <td>{invoiceData.creditRate}</td>
                            <td>{`${currencyFormatter(invoiceData.signedSubTotal, invoiceData.currencyIso3)}`}</td>
                        </tr>

                        <tr className="table-row-dark" style={{fontWeight: "bold"}}>
                            <td colSpan={3} className="text-end">{t("dashboard-amount-excl-vat")}</td>
                            <td>{`${currencyFormatter(invoiceData.signedSubTotal, invoiceData.currencyIso3)}`}</td>
                        </tr>
                        <tr className="table-row-dark" style={{fontWeight: "bold"}}>
                            <td colSpan={3} className="text-end">{t("dashboard-vat")}{` ${invoiceData.vat}%`}</td>
                            <td>{`${currencyFormatter(invoiceData.signedVatTotal, invoiceData.currencyIso3)}`}</td>
                        </tr>
                        <tr className="table-row-dark" style={{fontWeight: "bold"}}>
                            <td colSpan={3} className="text-end">{t("dashboard-total-amount-incl-vat")}</td>
                            <td>{`${currencyFormatter(invoiceData.signedTotal, invoiceData.currencyIso3)}`}</td>
                        </tr>
                        <tr className="table-row-dark" style={{fontWeight: "bold"}}>
                            <td colSpan={3} className="text-end">{t("dashboard-payment-method")}</td>
                            <td>{`${invoiceData.paymentProviderMethod}`}</td>
                        </tr>
                        </tbody>
                    </table>

                    <p className="my-4">{t("dashboard-vat-registration-number")} {VAT_REGISTRATION_NUMBER}</p>
                </div>

            </Modal>
        </div>
    );
};

export default Dashboard;
